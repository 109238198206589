body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Eurostile Regular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'Eurostile Regular';
  src: url('/src/app/assets/fonts/eurostile.woff') format('woff'),
       url('/src/app/assets/fonts/EuroStyle-Normal.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

header {
  position: fixed !important;
  top:0 !important;
  z-index: 9999 !important ;
  transform: none !important;
    visibility: visible !important;
}
.timelineContainer, .MuiStack-root {
  width: 100% !important;
}

.MuiTab-root {
  font-size: 1rem !important;
  font-weight: 600 !important;
}

.MuiStepLabel-label {
  font-family: 'Eurostile Regular' !important;
}

.MuiTypography-root {
  font-family: 'Eurostile Regular' !important;
}

.MuiInputBase-input {
  font-family: 'Eurostile Regular' !important;
}

.MuiFormLabel-root {
  font-family: 'Eurostile Regular' !important;
}

.MuiButtonBase-root {
  font-family: 'Eurostile Regular' !important;
  font-size: 1.1rem !important;
}

p {
  font-size: 1.05rem !important;
}

span {
  font-size: 1.05rem !important;
}

input {
  font-size: 1.05rem !important;
}

.MuiButton-root {
  font-size: 0.85rem !important;
  font-weight: bold !important;
}


 .RaMenuItemCategory-active{
  background-color: #f0f2f0 !important;
  border-left: 4px solid #264796;
  border-radius: 4px;
}

@media (max-width:900px) {
.thText {
  display: none !important;
}

.parcelDescription {
  display: none !important;
}
}


.RaMenuItem{
  background-color: #f0f2f0 !important;
  border-right: 2px solid #264796 !important;

}


.dimms .MuiOutlinedInput-input{
  font-weight: 700;
}

.parcelInputsEnd {
    right: 0px;
    width: 2.5rem;
    height: 40px;
    font-size: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    margin-left: 240px;
    top: 0;
    z-index: 0;
    border-radius: 4px;
    background: rgba(0, 101, 178, 0.3);
    color: #264796;
    font-family: 'Eurostile Regular'
}

.parcelInputsView {
  right: 0px;
  height: 40px;
  font-size: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  z-index: 0;
  border-radius: 4px;
  background: rgba(0, 101, 178, 0.3);
  color: #264796;
  font-family: 'Eurostile Regular'
}

.parcelInputsSettings {
  right: 0px;
  width: 2.5rem;
  height: 40px;
  font-size: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  z-index: 0;
  border-radius: 4px;
  background: rgba(0, 101, 178, 0.3);
  color: #264796;
}

.centeredDiv {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.css-o0rgu6 {
  padding-right: 5px;
}

.centeredText {
  height: 40px;
    background-color: rgb(192, 211, 231);
    display: flex;
    align-items: center;
    justify-content: center;
}

.dimms {
  display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    position: relative;
    /* padding-left: 10px; */
}

.footer {
  display: flex;
  justify-content: center;
  padding: 10px;
  position: -webkit-sticky;
  bottom: 0;
  position: sticky;
  flex-direction: row;
  flex-wrap: nowrap;
  background-color: white;
  height: 60px;

  z-index: 1;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
}

.footerSettings {
  display: flex;
  justify-content: flex-end;
  width: 80%;
  margin: 0 6.5% 0 0;
  padding: 10px;
  gap: 40px;
  bottom: 0;
  position: fixed;
  flex-direction: row;
  flex-wrap: nowrap;
  background-color: white;
  height: 60px;
  /* width: -webkit-fill-available; */
  margin-top:20px;
  flex-direction: row;
}

.footerRight {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}


.footerLeft {
  background: rgb(226, 117, 58);
}

@media screen and (min-width: 30em) {
.css-a7qlup {
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin: 0px 3rem;
}
}


.sortBox {
 cursor: pointer;
 width: 16.66%;
 padding: 12px;
 color: #000;
}

.sortBox1 {
  cursor: pointer;
  width: 16.66%;
  padding: 12px
 }

 .sortBox2 {
  cursor: pointer;
  width: 50%;
  padding: 12px
 }

.sortBox:hover{
  background-color: #ebebeb;
  color:'#ed7d31';
}

.sortBox:active{
  background-color: #ebebeb;
  color:'#ed7d31';
}
.datePicker {
  display: flex;
    flex-direction: column;
    align-items: center;
    color: #264796;
    justify-content: center;
}

.boxText {
  color: #ed7d31;
}

.parcel_animate {
  animation: bounceTop 0.5s ease;
}

@keyframes bounceTop {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }
  50% {
    opacity: 1;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

table, tr {
  border-bottom: 1px solid rgb(231, 231, 231);
  text-wrap: wrap;
}



th {
  padding: 10px;
  text-wrap: wrap;

}

.th {
  background-color: #264796;
  color: white;
  border-radius: 5px;

}

table, td {
  font-size: 0.85rem;
}

.pac-container {
  background-color: #fff;
  position: absolute!important;
  border-radius: 2px;
  border-top: 1px solid #d9d9d9;
  font-family: -apple-system, BlinkMacSystemFont, 'Eurostile Regular';
  -webkit-box-shadow: 0 2px 6px rgba(0,0,0,.3);
  box-shadow: 0 2px 6px rgba(0,0,0,.3);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  z-index: 99999999999;
}

.address-box {
  padding: 10px;

  transition: background-color 0.3s;
}

.address-box:hover {
  background-color: #f0f0f0;
  border-radius: 3px;
}


.overflow-ellipsis {
  /* white-space: nowrap; 
  width: 60%;  */
  overflow: hidden;
  text-overflow: ellipsis;
}

.trackIndicator {
  display: flex;
  background-color: rgb(243, 109, 37);
  height: 8px;
  width: 8px;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 50%;
  -webkit-box-pack: center;
  justify-content: center;
}

.trackLine {
  display: flex;
  background: rgb(243, 109, 37);
  left: 172.5px;
  position: absolute;
  right: 0px;
  height: 2px;
}

.burgerIcon {
  cursor: pointer;
  z-index: 5;
  overflow: visible;
}

.burgerIcon > rect {
  transform-origin: center;
  transform-box: fill-box;
  color: #fff;
}

/* .shipInfoBox {
  width: 49.38%;
} */

.lowlighted {
  color: #999;
}

.highlighted {
  color: #000000
}


@media (max-width: 830px) {
  .parcelInputsView {
display: none;
  }
}


/* CSS variables. */
:root {
	--PhoneInput-color--focus: #03b2cb;
	--PhoneInputInternationalIconPhone-opacity: 0.8;
	--PhoneInputInternationalIconGlobe-opacity: 0.65;
	--PhoneInputCountrySelect-marginRight: 0.35em;
	--PhoneInputCountrySelectArrow-width: 0.3em;
	--PhoneInputCountrySelectArrow-marginLeft: var(--PhoneInputCountrySelect-marginRight);
	--PhoneInputCountrySelectArrow-marginTop: calc(var(--PhoneInputCountrySelectArrow-height) / 2);
	--PhoneInputCountrySelectArrow-borderWidth: 1px;
	--PhoneInputCountrySelectArrow-opacity: 0.45;
	--PhoneInputCountrySelectArrow-color: inherit;
	--PhoneInputCountrySelectArrow-color--focus: var(--PhoneInput-color--focus);
	--PhoneInputCountrySelectArrow-transform: rotate(45deg);
	--PhoneInputCountryFlag-aspectRatio: 1.5;
	--PhoneInputCountryFlag-height: 1em;
	--PhoneInputCountryFlag-borderWidth: 1px;
	--PhoneInputCountryFlag-borderColor: rgba(0,0,0,0.5);
	--PhoneInputCountryFlag-borderColor--focus: var(--PhoneInput-color--focus);
	--PhoneInputCountryFlag-backgroundColor--loading: rgba(0,0,0,0.1);
}

.PhoneInput {
	/* This is done to stretch the contents of this component. */
	display: flex;
	align-items: center;
  border: 1px solid lightgrey;
  border-radius: 5px;
  padding: 8.5px 14px;
    
}

.PhoneInputInput {
	/* The phone number input stretches to fill all empty space */
	flex: 1;
	min-width: 0;
  border: none;
  height: 1.2375em;
  font-size: 1em;
  font-weight: 400;
  font: inherit;
  letter-spacing: inherit;
  
}

.PhoneInputInput:focus-visible {
  outline: none !important;
}

.PhoneInputCountryIcon {
	width: calc(var(--PhoneInputCountryFlag-height) * var(--PhoneInputCountryFlag-aspectRatio));
	height: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--square {
	width: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--border {
	/* Removed `background-color` because when an `<img/>` was still loading
	   it would show a dark gray rectangle. */
	/* For some reason the `<img/>` is not stretched to 100% width and height
	   and sometime there can be seen white pixels of the background at top and bottom. */
	background-color: var(--PhoneInputCountryFlag-backgroundColor--loading);
	/* Border is added via `box-shadow` because `border` interferes with `width`/`height`. */
	/* For some reason the `<img/>` is not stretched to 100% width and height
	   and sometime there can be seen white pixels of the background at top and bottom,
	   so an additional "inset" border is added. */
	box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor),
		inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor);
}

.PhoneInputCountryIconImg {
	/* 3rd party <SVG/> flag icons won't stretch if they have `width` and `height`.
	   Also, if an <SVG/> icon's aspect ratio was different, it wouldn't fit too. */
	width: 100%;
	height: 100%;
}

.PhoneInputInternationalIconPhone {
	opacity: var(--PhoneInputInternationalIconPhone-opacity);
}

.PhoneInputInternationalIconGlobe {
	opacity: var(--PhoneInputInternationalIconGlobe-opacity);
}

/* Styling native country `<select/>`. */

.PhoneInputCountry {
	position: relative;
	align-self: stretch;
	display: flex;
	align-items: center;
	margin-right: var(--PhoneInputCountrySelect-marginRight);
}

.PhoneInputCountrySelect {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 1;
	border: 0;
	opacity: 0;
	cursor: pointer;
}

.PhoneInputCountrySelect[disabled] {
	cursor: default;
}

.PhoneInputCountrySelectArrow {
	display: block;
	content: '';
	width: var(--PhoneInputCountrySelectArrow-width);
	height: var(--PhoneInputCountrySelectArrow-width);
	margin-top: var(--PhoneInputCountrySelectArrow-marginTop);
	margin-left: var(--PhoneInputCountrySelectArrow-marginLeft);
	border-style: solid;
	border-color: var(--PhoneInputCountrySelectArrow-color);
	border-top-width: 0;
	border-bottom-width: var(--PhoneInputCountrySelectArrow-borderWidth);
	border-left-width: 0;
	border-right-width: var(--PhoneInputCountrySelectArrow-borderWidth);
	transform: var(--PhoneInputCountrySelectArrow-transform);
	opacity: var(--PhoneInputCountrySelectArrow-opacity);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon + .PhoneInputCountrySelectArrow {
	opacity: 1;
	color: var(--PhoneInputCountrySelectArrow-color--focus);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
	box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor--focus),
		inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor--focus);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon .PhoneInputInternationalIconGlobe {
	opacity: 1;
	color: var(--PhoneInputCountrySelectArrow-color--focus);
}


.testLabel {
  color: rgba(0, 0, 0, 0.6);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  padding: 2px 4px;
  position: relative;
  display: block;
  transform-origin: top left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(133% - 32px);
  position: absolute;
  /* left: 0; */
  /* top: 0; */
  transform: translate(14px, -9px) scale(0.75);
  transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  z-index: 1;
  pointer-events: auto;
  -webkit-user-select: none;
  user-select: none;
  background: white;
}

/*  TO RESOLVED CSS STYLES */

.css-ply0xg-RaMenuRoot-root .RaMenuRoot-list {
  padding-top: 50px !important;
}

.css-ply0xg-RaMenuRoot-root {
  border-right: 1px solid #264796 !important;
}

.css-10vb1ni-MuiDrawer-docked-RaSidebar-root.RaSidebar-appBarCollapsed {
  margin-top: 0 !important;
}

.css-1we0tu4-MuiTypography-root {
    font-size: 0.9rem !important;
  }
  
  @media (min-width:600px) and (max-width:899px) {
    .times {
      flex-direction: column !important;
      min-width: 60vw !important;
    }
  
    .css-bqifgk {
      font-size: 12px !important;
      font-weight: normal !important;
    }
  
  }


  @media (min-width:900px) and (max-width:1199px) {

    .css-4zz9y7-MuiGrid-root {
      -webkit-flex-direction: column !important;
      -ms-flex-direction: column !important;
      flex-direction: column !important;
      margin-top: -16px;
      width: calc(100% + 16px);
      margin-left: -16px;
      margin-bottom: 40px;
  }
  
  .css-4zz9y7-MuiGrid-root>.MuiGrid-item {
    padding-left: 16px;
    max-width: 100%;
  }
  
  }

  @media (min-width:1200px) and (max-width:1524px) {
    .css-a5rdam-MuiGrid-root {
      width: 100%;
      -webkit-flex-direction: column !important;
      -ms-flex-direction: column !important;
      flex-direction: column !important;
      margin-top: -8px;
      width: calc(100% + 8px);
      margin-left: -8px;
  }
  
  .css-f0xsrb-MuiGrid-root {
    -webkit-flex-basis: 100% !important;
    -ms-flex-preferred-size: 100% !important;
    flex-basis: 100% !important;
    max-width: 100% !important;
  }
  
  .css-obn8d-MuiGrid-root {
    -webkit-flex-basis: 100% !important;
    -ms-flex-preferred-size: 100% !important;
    flex-basis: 100% !important;
    max-width: 100% !important;
  }
  
  }
  

  @media (min-width:500px) and (max-width:1443px) {
    .css-mhc70k-MuiGrid-root {
      flex-direction: column !important;
  }
  
    .css-fnq3u3-MuiGrid-root {
      -webkit-box-pack: start !important;
      -ms-flex-pack: start !important;
      -webkit-justify-content: flex-start !important;
      justify-content: flex-start !important;
      max-width: 100% !important;
    }
  
  }
  
  .css-1tf56z8-MuiPaper-root-MuiAppBar-root-RaAppBar-root {
    height: 75px;
    justify-content: center;
  }
  


.css-10vb1ni-MuiDrawer-docked-RaSidebar-root .MuiPaper-root {
    border: none;
    width: 240px !important;
  }
  
  .css-1h9z4nm-RaMenuRoot-root .RaMenuRoot-list {
    width: 200px !important;
  }
  
  .css-1xmwc10-RaMenuItemCategory-root {
    width: 192px !important;
    padding: 4px !important;
  }
  
  .css-1xmwc10-RaMenuItemCategory-root .RaMenuItemCategory-link span {
    font-size: 1.15rem !important;
    padding-left: 10px !important;
  }
  
  .css-1xmwc10-RaMenuItemCategory-root .RaMenuItemCategory-link {
    white-space: nowrap;
    -webkit-text-decoration: none;
    text-decoration: none;
    color: inherit;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: row !important;
    -ms-flex-direction: row !important;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: flex-start;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    flex-direction: row !important;
  }
  
  .css-1xmwc10-RaMenuItemCategory-root .RaMenuItemCategory-container {
    cursor: pointer;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: flex-start !important;
    -webkit-box-align: center;
    -ms-flex-align: flex-start !important;
    align-items: flex-start !important;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    padding: 8px;
  }
  

  .css-10vb1ni-MuiDrawer-docked-RaSidebar-root .RaSidebar-fixed {
    height: 100vh !important;
  
  }
  
  .css-1h9z4nm-RaMenuRoot-root .RaMenuRoot-list {
    padding-top: 80px !important;
  }
  
  .css-2u1dnf-MuiDrawer-docked-RaSidebar-root {
    height: 100vh !important;
    padding-top: 50px !important;
  }
  

.css-pcd6pd {
    display: flex;
    margin-bottom: 0px;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    width: 10%;
    border: 1px dashed rgba(0, 101, 178, 0.3);
  
  }
  
  .css-kosbw1 {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;

  }
  

  .css-a7qlup {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    padding-top: 0px;
    padding-bottom: 0px;
    margin: 0.5rem auto;
}


.css-14d6vet-MuiCardContent-root:last-child {
    padding-bottom: 0px !important;
  }
  

.css-pod7ec-MuiFormControlLabel-root-RaCheckboxGroupInputItem-root {
    margin-left: 0px !important;
    margin-right: 0px !important;
  
  }
  

.css-ahj2mt-MuiTypography-root {
    margin: 0;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    font-size: 0.85rem !important;
    line-height: 1.5;
    letter-spacing: 0.00938em;
  }


.css-1h9z4nm-RaMenuRoot-root {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    z-index: 1099;
    min-height: 100vh;
    color: #264796;
    background-color: #FFFFFF;
    width: 96%;
    border-right: 2px solid #264796 !important;
  }

  .css-1f7shrz-MuiButtonBase-root-MuiButton-root-RaButton-root {
    min-width: auto !important;
    padding: auto !important;
  }
  
  @media (max-width: 1443px) {

    .css-inp45n-MuiGrid-root {
      max-width: 100% !important;
  
    }
  
  
    .css-p51kcb-MuiGrid-root {
      -webkit-align-items: none !important;
      -webkit-box-align: none !important;
      -ms-flex-align: none !important;
      align-items: none !important;
  
  }
  
  .css-12xeub0-MuiGrid-root {
    -webkit-flex-basis: 100% !important;
    -ms-flex-preferred-size: 100% !important;
    flex-basis: 100% !important;
    max-width: 100% !important;
  }
  
  }
  


.css-1qgma8u-MuiButtonBase-root-MuiTableSortLabel-root.Mui-active {
    color: #ffffff !important;
  }
  
  .css-1qgma8u-MuiButtonBase-root-MuiTableSortLabel-root.Mui-active .MuiTableSortLabel-icon {
    opacity: 1;
    color: rgb(255 255 255 / 60%) !important;
  }
  
  .css-rhrc4j-MuiContainer-root {
    padding-bottom: 0px !important;
  }
  
  .css-1yado2t {
    margin-bottom: 0px !important;
  }
  
  .css-18x7v7v {
    background: #EEF2F6;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border: 1px solid #C0D3E7;
    border-radius: 8px;
    padding: 6px !important;
    padding-left: 10px !important;
    box-shadow: none !important; 
  }
  
  .css-o4b71y-MuiAccordionSummary-content.Mui-expanded {
    margin: 0px !important;
  }
  
  .css-1awf74c-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
    min-height: 24px !important; 
  }
  
  .css-g5x3vr-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
    min-height: 44px !important; 
  }
  
  @media screen and (max-width: 500px) {
  
    .css-rhrc4j-MuiContainer-root {
      width: 100% !important;
      margin-left: auto !important;
      margin-right: auto !important; 
  }
  
  .css-bqifgk {
    font-size: 12px !important;
    font-weight: normal !important;
  }
  
  .css-mf77hp {
    width: 20% !important;
  }
  
  .css-57e5bd-MuiTypography-root {
    font-size: 0.6rem !important;
  }
  
  .css-pqkzyh-MuiGrid-root {
    width: 100% !important;
    margin-bottom: 20px !important;
  
  }
  
  .css-7k4ndg-RaListToolbar-root {
    display: flex;
    position: relative;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-align-items: flex-start !important ; 
    -webkit-box-align: flex-start !important;
    -ms-flex-align: flex-start !important;
    align-items: flex-start !important; 
    width: 20%;
  }
  
  
  
  }
  
  @media (min-width: 0px) and (max-width: 599.95px){
  
  .css-35gbcm-MuiModal-root-MuiDrawer-root-RaSidebar-root .MuiPaper-root {
      margin-top: 75px !important;
      height: 100vh;
      position: inherit;
      background-color: #fafafb;
      width: fit-content !important;
  }
  
  }
  
  .css-1wf8b0h-MuiTabs-flexContainer {
  
    background: #f0f0f2 !important;
  }
  
  @media (max-width: 599.95px) {
  
    .css-18hcsan-RaFilterForm-root {
        width: 21.5% !important;
        gap: 6px;
    }
  
    .css-knw8zc-RaFilterFormInput-root {
      display: block !important;
      display: block !important;
      display: block !important;
      display: block !important;
      -webkit-align-items: flex-end;
      -webkit-box-align: flex-end;
      -ms-flex-align: flex-end;
      align-items: flex-end;
      pointer-events: auto;
    }

  }

  .css-jtlhu6-MuiTablePagination-root:last-child {
    padding: 0;
    bottom: 0;
    position: relative;
      right: 100px;
      margin-left: 6.9%;
      background: white;
      width: 100%;
  }

  .PhoneInputInput {
    width: 100%;
  }

  .dimensionSpan {
    margin-left: 20px;
    font-size: 14px !important;
  }

/*  TO RESOLVED CSS STYLES */